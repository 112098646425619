import React from 'react';

function Community(){
    return (
      <div className="container flexPadd">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="text-center pt-5">A Caring Community of Faith</h3>
            <div className="animated slideInUp">
              <h5>Worship Together</h5>
              <p>
                Worship is central to the life of King's Chapel. Each Sunday we
                gather at 11:00 a.m. to worship, learn and to gain personal
                strength from God's word and from each other. Communion is
                served periodically throughout the year. A nursery is provided
                during worship services.
              </p>
              <p>
                To further enrich of faith journey, we offer special worship
                services on Easter Sunday and during the Advent/Christmas
                season.
              </p>
            </div>
            <div className="animated slideInUp">
              <h5>Learning Together</h5>
              <p>
                We recognize the importance of Christian education enhancing our
                personal understanding of God's word. Every Sunday morning at
                10:00 a.m., we offer Sunday School classes for children and
                adults followed by a coffee break before worship.
              </p>
              <p>We also offer activities such as:</p>
              <ul>
                <li>Presbyterian Women: a monthly women's Bible study</li>
                <li>Young Disciples: our monthly youth summer gathering</li>
                <li>Summer Vacation Bible School: for children's and adults</li>
              </ul>
            </div>
            <div className="animated slideInUp">
              <h5>Serving Together</h5>
              <p>
                In answering God's call to be caring community of faith, King's
                Chapel responds to the human and spiritual needs of those in our
                own community and around the world.
              </p>
              <p>In our local community we support:</p>
              <ul>
                <li>the Central Virginia Food Bank</li>
                <li>the Ashland Christian Emergency Services</li>
                <li>the Ashland Convalescent Center</li>
                <li>a family in need during the holidays</li>
              </ul>
              <p>In the broader community we support:</p>
              <ul>
                <li>
                  World Vision hunger relief, One Great Hour of Sharing, and the
                  Two Cents a Meal program
                </li>
                <li>
                  ongoing global aid efforts such as sending baby blankets,
                  bandages and school supplies to children in need overseas.
                </li>
              </ul>
            </div>
            <div className="animated slideInUp">
              <h5>Rejoicing Together</h5>
              <p>
                One of the best things about King's Chapel is the time we spend
                together in fellowship and fun! <br /> We have:
              </p>
              <ul>
                <li>
                  frequent fellowship luncheons after Sunday worship services
                </li>
                <li>an annual harvest festival in October</li>
                <li>an annual Christmas dinner and program</li>
                <li>an annual Easter Sunday country breakfast</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Community;